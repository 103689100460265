$transition-speed: 0.3s;
@import '../../../../../../styles/main.scss';
.pendientes-list {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  min-height: 49px;

  .autocomplete-pendientes-list {
    width: 200px !important;
  }
}

.pendientes-list-item {
  border-bottom: 1px solid #ddd;
  background: #fff;
  transition: background $transition-speed;
  width: 100%;

  &.active {
    background: #fff;
  }

  &:last-child {
    border-bottom: none;
  }
}

.pendientes-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  background: $white;
  border: 1px solid $primary;
  border-radius: 5px;
  transition: background $transition-speed;

  svg {
    color: rgba(0, 0, 0, 0.54);
  }

}

.icon {
  transition: transform $transition-speed;
  
  &.rotate {
    transform: rotate(180deg);
  }
}

.pendientes-list-content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  padding: 0 15px 15px;
  transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out, padding 0.3s;
}

.pendientes-list-item.active .pendientes-list-content {
  opacity: 1;
  padding: 15px;
}
